/* remove  */
@media only screen and (min-width:1300px)
{
    .container.container_custom
    {
        max-width: 90%;
    }
}
/* end remove  */

.adddate{
    position: relative;
}
/* common */
.btn_blue
{
    background-color: #082e54;
    color:#fbc108;
    padding: 1px 8px;
    height: 34px !important;
    width: 34px !important;
}
.btn_blue:hover
{
    background-color: #fbc108;
    color:#082e54;
}
/* end common */

.card_nav
{
    border:none;
}
.card_nav ul
{
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 7px;
    /* max-width: fit-content; */
    border:1px solid #092d53;
    border-radius: 10px !important;
}
.li-inline-header
{
    display: inline-block;
    padding-left: 0px;
    margin-bottom: 0px;
    padding: 3px 0px;
}
.li-inline-header li
{
    display: inline;
    position: relative;
    padding-right: 20px;
    color: #2b4865 !important;
    font-size: 12px;
    font-weight: 500 !important;
}
.li-inline-header li:after
{
    content: "";
    border-right: 1px solid #092d53;
    position: absolute;
    right: 10px;
    width: 20px;
    height: 15px;
    top: 0px;
}
.li-inline-header li:last-child::after
{
    content: none !important;
}
.li-inline-header li:last-child
{
    padding-right: 10px !important;
}
.li-inline-header li:first-child
{
    padding-left: 10px;
}

.btn_grey
{
    background-color: #e4e7ec;
    border:1px solid #e4e7ec;
    color:#1e4061;
    font-size: 12px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
}
.btn_trans_icon
{
    background:transparent !important;
    color:#082e54 !important;
}
.btn_trans_icon svg
{
    color:#082e54 !important;
    fill:#082e54 !important;
    display: block;
    height: 16px;
    width: 16px;

}

.dropdown .btn_trans_icon::after
{
content: none;
}
.dropdown .btn_trans_icon
{
    border:none !important;
}
.btn:focus{
    box-shadow: 0 0 0 0rem rgb(60 153 110 / 50%) !important;
}
.dd_menu_shadow
{
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
    border-radius: 10px !important;
    border:none !important;
    min-width: 180px !important;
    right: 30px !important;
}
.dd_menu_shadow a
{
    font-size: 14px !important;
    margin-bottom: 10px;
}
.dd_menu_shadow .dropdown-item:focus,
.dd_menu_shadow  .dropdown-item:hover,
.dd_menu_shadow  .dropdown-item:active
{
    color: #1e2125 !important;
    background-color: #e9ecef !important;
}
.dropdown-divider
{
    border-color: #b5b4b4 !important;
}
.header
{
    border-bottom: 1px solid #f0f0f0 !important;
}
.tab_header_main
{
    border-bottom: none !important;
    text-align: center;
    align-items: center;
    justify-content: center;
}


.tab_header_main .nav-link.active,.tab_header_main .nav-link:hover, .mobilewhere .nav-link.active,
.mobilewhere .nav-link:hover
{
    border-bottom: 5px solid #082e53;
    border-top: none;
    border-left: none;
    border-right: none;
    color:#667683;
    background-color: transparent !important;
    background-image:linear-gradient(358deg, #f1eff0, white)!important;
    /* background-image: linear-gradient(90deg,#f5f3f4 37%,#f3eff0 55%,#f3eff0 75%)!important; */
    border-radius: 5px !important;


}/* viji */
.mobilewhere .accordion-header button p:last-child {
    color: gray;
  }
  .mobilewhere .accordion-header button p {
    margin-bottom: 0;
  }
  .mobilewhere .accordion-item {
    margin-bottom: 13px;
    border: 1px solid #d4d3d3;
    border-radius: 5px;
  }
.mobilewhere .nav-link {
    color: #262626;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px !important;
    cursor: pointer;
    padding: 6px 10px;
    border-bottom: 5px solid transparent;
    margin-right: 3px;
  }     /* viji */
  .mobilewhere .accordion-header button {
    background: transparent;
    color: black;
    font-size: 14px;
  } /* viji */
  .mobilewhere .accordion-header button:focus {
    box-shadow: none;
  }
  .mobilewhere .grid_calendar_div{
    display: block !important;
  }
  
  .mobilewhere .input-group-text {
    background: transparent;
    color: var(--themeclr);
    font-size: 12px;
}
.mobilewhere  input:focus{
    box-shadow: none;
}
.tab_header_main .nav-link
{
    color:#262626;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px !important;
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 5px solid transparent;
    margin-right: 20px;

}
.tab_header_main .nav-item:last-child .nav-link
{
    margin-right: 0px !important;

}

/* .tab_header_main .nav-link:last-child
{
    margin-right: 0px !important;

} */

.dropdown.dd_dropdown_card:before {
    /* position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(100,100,100,0.5);
    content: '';
    z-index: 1; */
}
.li-inline-card
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
    width: 100%;
}
.li-inline-card li
{
    display: inline;
    list-style-type: none;
}
.tab_header_main .nav-pills
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.inside_card_head
{
    border: 1px solid #092d53;
    border-radius: 10px !important;
    /* background-color: transparent; */
}
.col_card_in:after {
    /* content: "";
    border-right: 2px solid #092d53;
    position: absolute;
    right: 10px;
    width: 20px;
    height: 50%;
    top: 25%; */
}
.inside_card_head 
{
    text-align: left !important;
}
.inside_card_head .col_card_in:last-child::after
{
    content: none !important;
}
.btn_pink_grad
{
    background-image: linear-gradient(90deg,#e51d4f 37%,#e1195f 55%,#db0b64 75%)!important;
    color:#fff !important;
    border-radius:25px;
    padding: 12px 20px;
    border:none !important;
}
.btn_pink_grad:hover
{
    background-image: linear-gradient(360deg,#e51d4f 37%,#e1195f 55%,#db0b64 75%)!important;

}
.col_card_in
{
    padding: 10px 15px;
    position: relative;
    margin: -1px;

}
.inside_card_heading
{
    color:#072f51;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
}
.form_control_card
{
    border:none;
    color:#657582;
    background-color: transparent !important;
    font-size: 14px;
    padding: 0px;
    box-shadow: 0 0 0 0rem #fff !important;
    margin-bottom: 0px;


}
.form_control_card::placeholder
{
    color:#657582;

}
.inside_card_heading_sm
{
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
    color:#677484;
}
.col_card_in:hover{
    /* box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%); */
    /* background-color: #082e53;
    border-radius: 10px;
    margin: -1px; */
}
.col_card_in:hover .inside_card_heading
{
color:#d2d6d9;
}
.col_card_in:hover .inside_card_heading_sm
{
color:#6e89a6 !important;
}
.col_card_in:hover .form_control_card
{
    color:#6e89a6 !important;

}
.col_card_in:hover .form_control_card::placeholder
{
    color:#6e89a6 !important;

}
.col_card_in:hover .btn_blue
{
    background-color: #fbc108;
    color: #082e54;
}
/* .col_card_in:last-child
{
margin-top: 0px !important;
margin-bottom: 0px !important;

} */
/* 
.canvas_dd
{
position: absolute;
top:80px !important;
} */
.dd_dropdown_card button
{
    text-align: left !important;
}
.dd_dropdown_card::before
{
    top:260px !important;
}
.dd_menu_shadow_card
{
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
    border-radius: 10px !important;
    border:none !important;
    /* min-width: 180px !important;  */
}
.col_card_in .btn_blue
{
    /* border-radius: 10px !important; */
    /* min-width: 44px; */
    height: 100%;
}
.user_dd
{
    display: inline !important;
}
.drop_header
{
    color: #ec6043;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}
.card_border_blue
{
 border-color: #082e53;   
}
.card_country
{
 border-color: #8b99a6;   
 border-radius: 10px !important;
 cursor: pointer;
    
}
.card_country:hover
{
 border-color: #082e53;   

}
.card_country img
{
 border-radius: 10px !important;
 margin: 1px;

}
.drop_header_country
{
    color: #082e53;
    font-size: 14px;
    font-weight: 500; 
    margin-top: 15px;
}
.dd_menu_shadow_card 
{
    padding: 20px;
}
.minus_div
{
    width: 32px;
    height: 32px;
    border: 1px solid rgb(176, 176, 176) !important;
    color: rgb(113, 113, 113) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500 !important;
}
.plus_div
{
    width: 32px;
    height: 32px;
    border: 1px solid rgb(176, 176, 176) !important;
    color: rgb(113, 113, 113) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;   
}
.who_title
{
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    padding-bottom: 6px !important;
    color: rgb(34, 34, 34) !important;
    margin-bottom: 0px;
}
.who_desc
{
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    color: rgb(113, 113, 113) !important;
}
.number_div
{
    font-size: 16px;
    color: rgb(34, 34, 34) !important;
}
.hr_who
{
    border-color: #ebebeb !important;
}
.who_service_1
{
    color: rgb(113, 113, 113) !important;
    text-decoration: underline;
    font-size: 14px !important;
    line-height: 18px !important;
    cursor: pointer;
}
.who_service_1:hover,.service_desc a:hover
{
    color:#082e53 !important;

}
.icon_div
{
    cursor: pointer;
}
.icon_div.disabled
{
    opacity: 0.5 !important;
    pointer-events: none;
    cursor: default !important;
}
.icon_div:hover
{
    border-color:var(--orange) !important;
    color:var(--orange) !important;

}
@media only screen and (min-width:576px)
{
    .dd_menu_shadow_card.who_card
    {
        min-width: 400px !important;
    }
}

@media only screen and (max-width:575px)
{
    .dd_menu_shadow_card.who_card
    {
        min-width: 100% !important;
    }
}
.dd_menu_shadow_card.who_card
{
    box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%);
}
.service_title
{
    font-size: 22px;
    font-weight: 600;
    color: #000 !important;
}
.service_desc,.service_desc a
{
    font-size: 14px;
    font-weight: 500;
    color: rgb(113, 113, 113) !important;
}
.close_modal
{
    color: rgb(113, 113, 113) !important;
    border:none;
    font-size: 30px !important;
}
.close_modal:hover
{
    color: rgb(0, 0, 0) !important;

}
.modal_scroll
{
    height: calc(100vh - 300px) !important;
}
.modal_style .modal-content
{
    /* box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px !important; */
    /* border-radius: 15px !important; */
}
@media only screen and (max-width:425px)
{
.who_sub .justify-content-end
{
    justify-content: flex-start !important;
    margin-top: 15px !important;
}
}

@media only screen and (min-width:576px)
{
    .dd_menu_shadow_card.country_card
    {
        min-width: 525px;
        /* min-width: 450px; */
    }
    .room_detail_date_picker {
        min-width: 430px !important;
      }
}
@media(max-width:991px){
    .btn_calc:first-child,.btn_calc:last-child {
        top: 72px !important;
    }
}
@media only screen and (max-width:575px)
{
    .dd_menu_shadow_card.country_card
    {
        /* min-width: 300px !important; */
        width: 250px !important;
        height: 300px;
        overflow: auto;
    }
}
.dd_menu_shadow_card.country_card
{
    box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%);
}

.header_2
{
    position: fixed;
    top: 0px;
    left: 0%;
    right: 0%;
    height: 0px; 
    width: 0px;
    overflow: hidden;
}

.header_2.opened{
      height: 100%; 
    width: 100%;
}

@media only screen and (max-width:767px){
    .row_nowrap
    {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
}

.where_tab
{
    border: 1px solid #092d53;
    border-radius: 10px !important;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto
}

.where_tab .nav-link.active,.where_tab .nav-link:hover
{
    background-color: #082e53 !important;
    background: #082e53 !important;

    color:#fff !important;
    border-bottom: none !important;
    border-radius: 10px !important;
}

.where_tab .nav-link
{
    color:#75818d !important;
    border-bottom: none !important;
    margin: -1px;
    margin-right: 10px !important;

}
.btn_blue_grp .btn
{
border-color: #a3abb5;
color:#a3abb5;
background-color: transparent !important;
margin-right: 15px;
border-radius: 10px !important;
padding-top: 10px !important;
padding-bottom: 10px !important;
min-width: 80px !important;
text-align: center !important;

}
.btn_blue_grp .btn:last-child
{
    margin-right: 0px;
}
.btn_blue_grp .btn:hover
{
    background-color: #092d50 !important;
    background: #092d50 !important;

    color:#fff;
    border-color: #092d50;
}
.month_title
{
    color:#4c5a67 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}
.year_title
{
    color:#737f8b !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}
.card_calendar
{
    border:2px solid #c7ccd2;
    border-radius: 10px;
    max-width: 100px !important;
    min-width: 100px !important;
    margin-right: 25px;
}
.calendar_div_scroll
{
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.calendar_scroll>div:nth-child(2)
{
    background-color: #dedfe1 !important;
}
.calendar_scroll>div:nth-child(2) div
{
    background-color: #082e53 !important;
}

.card_calendar
{
    cursor: pointer;
}

.card_calendar:hover
{
    border-color: #082e53;
}

/* calendar css */
.month_label
{
    color:#082e53;
}
.day_label
{
    color:#818181;
    font-size: 12px !important;
    font-weight: 500 !important;
}
.day_count_label
{
    color:#818181;
    font-size: 12px !important;
    font-weight: 500 !important; 
    border: none;
    padding: 8px;
    background: transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
}
.day_count_label:hover,.day_count_label.active
{
    background: #082e53;
    color: #fff !important;

}
.btn_calc:first-child
{
    position: absolute;
    left:32px;
    top:98px;
}
.btn_calc:last-child
{
    position: absolute;
    right:32px;
    top:98px;
}
.btn_calc
{
    border: none !important;
    background: transparent !important;
    color: #818181;
}
.btn_calc:hover
{
    color:#082e53;

}
.btn_cal_grp .btn
{
    font-size: 12px !important;
    min-width: 50px !important;
}

@media only screen and (min-width:992px) and (max-width:1299px)
{
    .grid_calendar_div
    {
    grid-template-columns: repeat(2, 47%) !important;
    gap: 0px 30px !important;
    }
    .day_count_label
    {
        width: 35px !important;
    height: 35px !important;
    }
}
/* end calendar css */




/* viji */
@media(max-width:425px){
    .mobilewhere .nav-link {
        font-size: 12px;
      } 
      .btn-group.btn_blue_grp {
        overflow: scroll;
        width: 242px;
        padding-bottom: 14px;
      }
}

.minus_div
{
    width: 32px;
    height: 32px;
    border: 1px solid rgb(176, 176, 176) !important;
    color: rgb(113, 113, 113) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500 !important;
    aspect-ratio: 1/1;

}
.plus_div
{
    width: 32px;
    height: 32px;
    border: 1px solid rgb(176, 176, 176) !important;
    color: rgb(113, 113, 113) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;   
    aspect-ratio: 1/1;
}
.who_title
{
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    padding-bottom: 6px !important;
    color: rgb(34, 34, 34) !important;
    margin-bottom: 0px;
}
.who_desc
{
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    color: rgb(113, 113, 113) !important;
}
.number_div
{
    font-size: 16px;
    color: rgb(34, 34, 34) !important;
}
.hr_who
{
    border-color: #ebebeb !important;
}
.who_service_1
{
    color: rgb(113, 113, 113) !important;
    text-decoration: underline;
    font-size: 14px !important;
    line-height: 18px !important;
    cursor: pointer;
}
.who_service_1:hover,.service_desc a:hover
{
    color:#082e53 !important;

}
.icon_div
{
    cursor: pointer;
}
.icon_div.disabled
{
    opacity: 0.5 !important;
    pointer-events: none;
    cursor: default !important;
}
/* .icon_div:hover
{
    border-color:#082e53 !important;
    color:#082e53 !important;

} */
@media only screen and (min-width:576px)
{
    .dd_menu_shadow_card.who_card
    {
        min-width: 400px !important;
    }
}

@media only screen and (max-width:575px)
{
    .dd_menu_shadow_card.who_card
    {
        min-width: 300px  !important;
    }
}
.dd_menu_shadow_card.who_card
{
    box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%);
}
.service_title
{
    font-size: 22px;
    font-weight: 600;
    color: #000 !important;
}
.service_desc,.service_desc a
{
    font-size: 14px;
    font-weight: 500;
    color: rgb(113, 113, 113) !important;
}
.close_modal
{
    color: rgb(113, 113, 113) !important;
    border:none;
    font-size: 30px !important;
}
.close_modal:hover
{
    color: rgb(0, 0, 0) !important;

}
.modal_scroll
{
    height: calc(100vh - 300px) !important;
}
.modal_style .modal-content
{
    /* box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px !important; */
    /* border-radius: 15px !important; */
}
@media only screen and (max-width:991px)
{
.who_sub .justify-content-end
{
    justify-content: flex-start !important;
    margin-top: 15px !important;
}
}


/* / SLIDER CSS / */
/* div[role="slider"]
{
    position: relative;
} */
div[role="slider"]::before
{
    content: "|||";
    position: absolute;
    width: 15px;
    height: 15px;
    left: 6.5px;
    top: 0.5px;
    color: #b0b0b0 !important;
}
div[role="slider"]:first-child
{
    margin-left: -26.5px !important;
}
div[role="slider"]:last-child
{
    margin-left: 0px !important;
}
.modal_title_blue
{
    color:#082e54;
    font-size: 16px;
    font-weight: 500;
    
}
.modal_head_flex
{
    justify-content: center;
}
.modal_head_flex .close_modal
{
    position: absolute;
    top: 0px;
    right: 20px;
}
.text_grey
{
    color:#7f8a95 !important;    
}
.font_14
{
    font-size: 14px !important;
}
.slider-ticks
{
    display: none;
}
.chart_div_canvas canvas
{
    max-height: 120px !important;
}
/* .modal_filter .modal-content
{
    padding-bottom: 100px !important;
} */
/* / ENS SLIDER CSS / */

.input_label_range label
{
    top:15px;
}
.input_label_range input
{
    margin-top:10px;
    padding-bottom: 10px;
    color: #082e54;
    font-weight: 700;

}
.input_label_range p
{
    margin-top:20px;
    color: #082e54;
    font-weight: 700;

}
.input_label_range fieldset
{
    border-radius: 8px;
}
.chart_div_canvas
{
    height: unset !important;
}
.custom_label_filter .label_blue_main
{
    color: #082e54 !important;
    font-size: 13px;
    font-weight: 500;
}
.sub_titl_blue
{
    color: #082e54 !important;
    font-size: 14px;
    font-weight: 600;
}
.custom_label_filter .label_grey_sub
{
    color: rgba(0, 0, 0, 0.54) !important;
    font-size: 13px;
    font-weight: 500;
}
.custom_label_filter
{
    position: relative;
}
.custom_label_filter input
{
    position: absolute;
    /* top: 4px;  */
}
.custom_label_filter label
{
    padding-left: 25px;
}
.form-check-input:checked
{
    background-color: var(--orange);
    border-color: var(--orange);
}
/* .btn_grp_blue .btn
{
    background-color: transparent;
    padding: 5px 20px;
    color:#697888;
    margin-right: 10px;
    border-radius: 5px !important;
    border:1px solid  #d6dde2 !important;
    font-size: 12px !important;
    min-width:40px;
}
.btn_grp_blue .btn:hover
{
    background-color: #082e54;
    color:#fff;
    border:1px solid  #082e54 !important;

} */
.blue_a_sm
{
    color: #082e54 !important;
    font-size: 13px;
    font-weight: 500;
}
.blue_a_sm:hover
{
    color: rgb(113, 113, 113) !important;
    
}

.card_grey
{
    background-color: #ecf0f3;
    border:none;
    border-radius: 10px;
}
.calendar_scroll.type_scroll
{
    width: 100% !important;
}
.calendar_scroll.room_scroll
{
    width: 100% !important;

}
.calendar_scroll.type_scroll .calendar_div_scroll
{
justify-content: space-between !important;
}
.btn_blue_grp.btn_width_unset .btn
{
    min-width: 50px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
/* .btn_blue_grp.btn_width_unset .btn:last-child
{
    margin-right: 20px !important;
} */
/* .calendar_scroll.type_scroll>div
{
    margin-right: 5px !important;
} */
/* .room_scroll>div
{
    margin-right: -10px !important;
} */
.calendar_div_scroll.calendar_div_scroll_filter .card_calendar
{
margin-right: 10px !important;
}
.calendar_div_scroll.calendar_div_scroll_filter .card_calendar .card-body
{
    padding: 20px 10px !important;
}
.f-14
{
    font-size: 14px;
}
.form-switch .form-check-input
{
    width: 50px;
    height: 32px;
}
.footer_flex_modal
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
}

.dd_dropdown_card .react-datepicker
{
border:none !important;
}
.dd_dropdown_card .react-datepicker__header 
{
background: transparent !important;
}
.btn_theme
{
    background: #ec6043;
    border:none;
    font-size: 14px;
    color:#fff;
}
.btn_theme:hover
{
    background-color: var(--lite-black-sub-heading);
    color: white;
}
.text-right
{
    text-align: right;
}
.right-head
{
    margin-top: 8px;
}

@media only screen and (min-width:760px) and (max-width:992px)
{
    .dd_menu_shadow_card.country_card
    {
        /* left:0% !important; */
    }
}