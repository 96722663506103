.bordbox {
  border: 1px solid lightgray;
}
.br-20 {
  border-radius: 15px;
}
.listingimg {
  height: auto;
  max-width: 300px;
}
.under {
  text-decoration: none;
}
.inlineblock li {
  display: inline-block;
}
.profile ul li {
  margin-right: 10px;
  margin-bottom: 10px;
}
.pagetop {
  padding-top: 100px;
}
.profile ul li a img {
  border-radius: 10px;
}
.profile .bordbox img {
  height: 120px;
  width: 120px;
  object-fit: cover;
  /* aspect-ratio: 1/1; */
  border-radius: 50%;
}
.nofification ul li img {
  height: 50px;
  width: 50px;
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.nofification ul li .text-dark {
  color: #000 !important;
}
.nofification ul li .btn-link {
  color: #f5593e !important;
}

.nofification ul li .btn-link:hover {
  color: #000 !important;
}

.detail-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile_listing_img {
  min-width: 240px;
  height: 240px;
}
.profile_listing_img img {
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .profile_listing_img {
    min-width: 150px;
    height: 180px;
    width: 180px;
  }
}
@media (max-width: 380px) {
  .profile_listing_img {
    /* min-width: 200px; */
    width: 100%;
    height: 200px;
  }
}
@media (min-width: 381px) and (max-width: 500px) {
  .profile_listing_img {
    min-width: 200px;
    /* width: 100%; */
    height: 200px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .profile_listing_img {
    min-width: unset !important;
    /* width: 100%; */
    height: 150px !important;
    width: 150px !important;
  }
}
